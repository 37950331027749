import {
    renderingRule,
    renderIfTokensMatch,
    renderWithAttrs,
} from "../utils/markdown.plugin.utils";

export const AddOrderedListClass = renderingRule(
    "ordered_list_open",
    renderIfTokensMatch({ level: 0 }, renderWithAttrs({ class: "top-level" }))
);

export default [AddOrderedListClass];
