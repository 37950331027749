import { defineMessages } from "react-intl.macro";

export default defineMessages({
    consoleSecretsTitle: {
        id: "console-secrets.title",
        description:
            'Displayed above "AWS Console Information" sidebar section',
        defaultMessage: "AWS Console Information",
    },
    consoleSecretsEC2KeysTitle: {
        id: "console-secrets.ec2-key-pairs-title",
        description:
            "Displayed above links to download EC2 Key Pairs (.pem or .ppk files)",
        defaultMessage: "EC2 Key Pair Private Key",
    },
    consoleSecretsEC2DownloadPEM: {
        id: "console-secrets.ec2-download-pem-link",
        description: "Text of action to download .pem file for EC2 access",
        defaultMessage: "Download PEM",
    },
    consoleSecretsEC2DownloadPPK: {
        id: "console-secrets.ec2-download-ppk-link",
        description: "Text of action to download .ppk file for EC2 access",
        defaultMessage: "Download PPK",
    },
    cfnExportsRegion: {
        id: "cfn-exports.region",
        description: "Label for region text",
        defaultMessage: "Region",
    },
});
