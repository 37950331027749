import { defineMessages } from 'react-intl.macro';

export default defineMessages({
    fatalHeader: {
        id: 'error.fatal-error.header',
        description:
            "Displayed to the user when something is so wrong we can't recover.  This is displayed in the header of a multiline alert box",
        defaultMessage: 'Something is wrong...',
    },
    fatalBody: {
        id: 'error.fatal-error.body',
        description:
            "Displayed to the user when something is so wrong we can't recover.  This is displayed in the body of a multiline alert box.",
        defaultMessage:
            'Reload the page now or try again after you have confirmed that you are signed in.',
    },
    noActiveLabHeader: {
        id: 'error.no-active-lab.header',
        description:
            'Displayed to a user when an active lab is not found.  This message is the header of an alert box.',
        defaultMessage: 'We could not find an active lab for you.',
    },
    noActiveLabBody: {
        id: 'error.no-active-lab.body',
        description:
            'Displayed to a user when an active lab is not found.  This message is the body of an alert box.',
        defaultMessage: 'Please confirm that you have an active lab to take.',
    },
    unauthorizedHeader: {
        id: 'error.not-authorized.header',
        description:
            'Displayed to the user when they are not authorized to view the lab. This text is displayed in the header of a multiline alert box.',
        defaultMessage: 'Your authorization has expired...',
    },
    unauthorizedBody: {
        id: 'error.not-authorized.body',
        description:
            'Displayed to the user when they are not authorized to view the lab. This text explains to the user what happened',
        defaultMessage:
            'Close the window and try to launch the lab again.',
    },
    accountLimitReachedHeader: {
        id: 'error.account-limit-reached.header',
        description:
            'Displayed to the user when they have taken too many labs in quick succession and a new account cannot be released.  This is displayed in the header of a multiline alert box',
        defaultMessage: 'Account Limit Reached',
    },
    accountLimitReachedBody: {
        id: 'error.account-limit-reached.body',
        description:
            'Displayed to the user when they have taken too many labs in quick succession and a new account cannot be released. This is displayed in the body of a multiline alert box.',
        defaultMessage:
            'Please wait 15 minutes to launch another lab. Please close this window and try again later.',
    },
    sessionErrorHeader: {
        id: 'error.session-error.header',
        description:
            'Displayed to the user when the session fails to provision',
        defaultMessage: 'Lab session failed',
    },
    sessionErrorBody: {
        id: 'error.session-error.body',
        description:
            'Displayed to the user when the session fails to provision',
        defaultMessage:
            'Close the page and try launching the lab again',
    },
});
