import React from "react";
import { prop } from "ramda";

import CodeBlock from "../components/CodeBlock";
import { renderingRule, renderReact } from "../utils/markdown.plugin.utils";

/**
 * REGEXP for TrimWhitespaceFromSource rendering rule. Splits input
 * into three groups where the second is the content of the code block
 * and the first and third are the HTML elements before and after the
 * content respectively, eg.
 *
 * 1. <pre class="hljs"><code>
 * 2. *Content of code block entered by author*
 * 3. </code></pre>
 *
 * @type {RegExp}
 */
const CODE_HTML_EXP = new RegExp("(.*<code>)([^]*)(</code>.*)", "m");

/**
 * Rendering rule for 'fence' (aka code blocks) that trims whitespace from the
 * content that will be inserted between <pre><code> and </code></pre>.
 *
 * ```
 *    some code be here
 *
 * ```
 *
 * is rendered as:
 *
 * <pre><code>some code be here</code></pre>
 */
export const TrimWhitespaceFromSource = renderingRule(
    "fence",
    ({ origRender }) => {
        const origRendered = origRender();
        const codeHtmlParts = CODE_HTML_EXP.exec(origRendered);
        if (codeHtmlParts && codeHtmlParts.length === 4) {
            return [
                codeHtmlParts[1],
                codeHtmlParts[2].trim(),
                codeHtmlParts[3],
            ].join("");
        }
        return origRendered;
    }
);

/**
 * Rendering rule that injects a React component for fence (source code) blocks
 * that has custom HTML/CSS and a button to copy text to the clipboard.
 */
export const CodeBlockComponent = renderingRule(
    "fence",
    renderReact(({ origContents, elementId, content }) => (
        <CodeBlock elementId={elementId} content={prop("content", content)}>
            {origContents()}
        </CodeBlock>
    ))
);

export default [TrimWhitespaceFromSource, CodeBlockComponent];
