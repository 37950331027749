import fileSaver from "file-saver";
import routes from "./routes";

export const nowTime = () => Date.now();

export const parseURIParams = uri =>
    uri.split("&").reduce((acc, set) => {
        const [key, val] = set.split("=");
        return { ...acc, [key]: val };
    }, {});

export const isDevelopment = () => process.env.NODE_ENV === "development";
export const mockDataMode = () =>
    isDevelopment() && process.env.REACT_APP_MOCK_DATA === "true";

export const copyToClipboard = ({ globals, elementSelector, value }) => {
    if (elementSelector) {
        value = (globals.document.querySelector(elementSelector) || {})
            .textContent;
    }
    if (value) {
        value = value.trim();
        if (value.indexOf("\n") >= 0) {
            // Append newline to multiline text as an application contention.
            // In lab sessions, multiline text often represents shell commands
            // that are intended to be executed in a terminal, where the trailing
            // newline executes the last one.
            value += "\n";
        }

        const tempInput = globals.document.createElement("textarea");
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = value;
        globals.document.body.appendChild(tempInput);
        tempInput.select();
        globals.document.execCommand("copy");
        globals.document.body.removeChild(tempInput);
    }
};

export const downloadText = (filename, content, type) => {
    fileSaver.saveAs(
        new Blob([content], { type: type || "text/plain;charset=utf-8" }),
        filename,
        true
    );
};

export const closeTab = globals => {
    if (isDevelopment()) {
        globals.location = routes.paths.devResources(
            "/end-session-redirect-page"
        );
    } else {
        globals.close();
    }
};

export const authorizationError = status =>
    typeof status === "number" &&
    status >= 0 &&
    /^40[13]$/.test(status.toString());

// pulled from https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_isempty?
export const isEmpty = obj =>
    [Object, Array].includes((obj || {}).constructor) &&
    !Object.entries(obj || {}).length;

export const msToSeconds = n => n / 1000;
