import { SUPPORTED_KEY_PAIR_FORMATS } from "../ConsoleSecrets/ConsoleSecrets";

const parseLabSecrets = (secrets = []) => {
    return secrets.reduce((acc, { secretName, secretValue }) => {
        const key = SUPPORTED_KEY_PAIR_FORMATS[secretName]
            ? "keyPairs"
            : "cfnExports";
        return {
            ...acc,
            [key]: {
                ...acc[key],
                [secretName]: secretValue,
            },
        };
    }, {});
};

const parseLabStatus = status => ({
    sessionLoading: ["INITIALIZING", "PROVISIONING"].includes(status),
    sessionReady: status === "READY",
    sessionActive: status === "RUNNING",
    sessionEnded: status === "ENDED",
    sessionError: status === "ERROR",
    sessionExpired: status === "EXPIRED",
});

export const parseLabData = data => ({
    ...data,
    ...parseLabStatus(data.status),
    parsedSecrets: parseLabSecrets(data.secrets),
});

export const parseMarkdownData = data => ({ body: data });
