import React, { useState } from "react";
import { Modal, Button } from "@amzn/awsui-components-react";
import { useIntl } from "react-intl";

import messages from "./Modal.messages";
import "./Modal.scss";

export const ExpiredModal = ({ onConfirm, isExpired }) => {
    const { formatMessage } = useIntl();
    const [wasDismissed, setDismissed] = useState(false);

    if (wasDismissed || !isExpired) return null;

    return (
        <Modal
            visible
            className="labs-modal"
            closeLabel={formatMessage(messages.closeButton)}
            header={formatMessage(messages.expiredHeadingText)}
            onDismiss={() => setDismissed(true)}
            footer={
                <span className="awsui-util-f-r">
                    <Button variant="primary" onClick={onConfirm}>
                        {formatMessage(messages.expiredButtonText)}
                    </Button>
                </span>
            }
        >
            {formatMessage(messages.expiredBodyText)}
        </Modal>
    );
};

export const ConfirmEndModal = ({
    onConfirm,
    isConfirming,
    setIsConfirming,
}) => {
    const { formatMessage } = useIntl();
    const [isInvoking, setIsInvoking] = useState(false);
    const onDismiss = () => setIsConfirming(false);

    const onConfirmClick = () => {
        setIsInvoking(true);
        onConfirm();
    };

    if (!isConfirming) return null;

    return (
        <Modal
            visible
            className="labs-modal"
            closeLabel={formatMessage(messages.closeButton)}
            header={formatMessage(messages.confirmHeadingText)}
            footer={
                <span className="awsui-util-f-r">
                    <Button variant="link" onClick={onDismiss}>
                        {formatMessage(messages.cancel)}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={onConfirmClick}
                        loading={isInvoking}
                    >
                        {formatMessage(messages.endSession)}
                    </Button>
                </span>
            }
            onDismiss={onDismiss}
        >
            {formatMessage(messages.confirmBodyText)}
        </Modal>
    );
};

export const MultipleSessionModal = ({ visible, onClose, errorConfirmed, concurrentLabsLimit=1 }) => {
    const { formatMessage } = useIntl();
    if (errorConfirmed || !visible) {
        return null;
    }
    const onDismiss = () => {
        onClose();
    };
    return (
        <Modal
            visible
            onDismiss={onDismiss}
            closeLabel={formatMessage(messages.closeButton)}
            header={formatMessage(messages.multipleSessionTitle)}
            footer={
                <span className="awsui-util-f-r">
                    <Button variant="primary" onClick={onDismiss}>
                        {formatMessage(messages.multipleSessionButton)}
                    </Button>
                </span>
            }
        >
            {
                concurrentLabsLimit === 1
                    ? formatMessage(messages.multipleSessionBody)
                    : formatMessage(messages.multipleSessionConfigurableBody,
                        {
                            number: concurrentLabsLimit
                        })
            }
        </Modal>
    );
};

export const WarningModal = ({ visible }) => {
    const { formatMessage } = useIntl();
    const [wasDismissed, wasDismissedSet] = useState(false);

    const onDismiss = () => wasDismissedSet(true);

    if (wasDismissed || !visible) return null;

    return (
        <Modal
            visible
            closeLabel={formatMessage(messages.closeButton)}
            header={formatMessage(messages.warningHeadingText)}
            onDismiss={onDismiss}
            footer={
                <span className="awsui-util-f-r">
                    <Button variant="primary" onClick={onDismiss}>
                        {formatMessage(messages.okButtonText)}
                    </Button>
                </span>
            }
        >
            {formatMessage(messages.warningBodyText)}
        </Modal>
    );
};
