// Fetch actionTypes and actionCreators
export const FETCH_REQUEST = "FETCH_REQUEST";
export const FETCH_ERROR = "FETCH_ERROR";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const CLEAR_DATA = "CLEAR_DATA";

export const clearData = api => ({ api, type: CLEAR_DATA });
export const fetchRequest = api => ({ api, type: FETCH_REQUEST });

export const fetchError = (api, error) => ({
    error,
    api,
    type: FETCH_ERROR,
});

export const fetchSuccess = (api, data) => ({
    data,
    api,
    type: FETCH_SUCCESS,
});
