import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { DevResourcesPage, SessionPage } from "..";
import { ErrorMessage } from "../../components";
import { Authenticated, Callback } from "../../components/Auth";
import { isDevelopment, mockDataMode } from "../../utils/helpers";
import routes from "../../utils/routes";

const { paths } = routes;

export const App = () => (
    <Switch>
        <Route path={paths.callback} component={Callback} />
        <Route
            path={paths.home}
            exact
            render={() => {
                const path = mockDataMode()
                    ? paths.devResources()
                    : paths.notFound;
                return <Redirect to={path} />;
            }}
        />
        {isDevelopment() && (
            <Route path={paths.devResources()} component={DevResourcesPage} />
        )}
        <Route
            path={paths.session(":id")}
            render={({ match }) => (
                <Authenticated>
                    <SessionPage labId={match.params.id} />
                </Authenticated>
            )}
        />
        <Route
            path={paths.notFound}
            render={() => <ErrorMessage type="notFound" />}
            exact
        />
        <Route render={() => <ErrorMessage type="notFound" />} />
    </Switch>
);

export default App;
