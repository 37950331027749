import hljs from "highlight.js/lib/highlight";
import { escapeHtml } from "markdown-it/lib/common/utils";

import "highlight.js/styles/vs.css";

// TODO: For the first cut, we are explicitly supporting the following languages, based on
// TODO: usage in existing labs and likely utilization with AWS training. In the future,
// TODO: we may want to be more open; eg. attempt to pull in a language on the fly based
// TODO: on a hint in the Markdown, or load all languages in highlight.js asynchronously
// TODO: after page load and before Markdown render.
hljs.registerLanguage("bash", require("highlight.js/lib/languages/bash"));
hljs.registerLanguage("cs", require("highlight.js/lib/languages/cs"));
hljs.registerLanguage(
    "dockerfile",
    require("highlight.js/lib/languages/dockerfile")
);
hljs.registerLanguage("java", require("highlight.js/lib/languages/java"));
hljs.registerLanguage(
    "javascript",
    require("highlight.js/lib/languages/javascript")
);
hljs.registerLanguage("json", require("highlight.js/lib/languages/json"));
hljs.registerLanguage("php", require("highlight.js/lib/languages/php"));
hljs.registerLanguage("python", require("highlight.js/lib/languages/python"));
hljs.registerLanguage("sql", require("highlight.js/lib/languages/sql"));
hljs.registerLanguage("xml", require("highlight.js/lib/languages/xml"));
hljs.registerLanguage("yaml", require("highlight.js/lib/languages/yaml"));

/**
 * See "Syntax highlighting" at https://github.com/markdown-it/markdown-it#usage-examples.
 *
 * Takes a string of language text and an optional language hint and returns an HTML string
 * for a <pre><code>...</code></pre> construction with classes from highlight.js if an
 * appropriate language can be found. If language hint is "plain", highlighting is not attempted
 * and a code block string with only escaped HTML is returned.
 *
 * @param {string} str  Code block body
 * @param {string} lang Language hint (text following opening ``` in Markdown)
 * @returns {string} HTML string for code block with highlight.js classes potentially in place.
 */
const highlight = (str, lang) => {
    if ("plain" !== lang) {
        if (lang && hljs.getLanguage(lang)) {
            try {
                return `<pre class="hljs"><code>${
                    hljs.highlight(lang, str, true).value
                }</code></pre>`;
            } catch (__) {
                // ignore exception
            }
        } else {
            try {
                return `<pre class="hljs"><code>${
                    hljs.highlightAuto(str).value
                }</code></pre>`;
            } catch (__) {
                // ignore exception
            }
        }
    }

    return `<pre class="hljs"><code>${escapeHtml(str)}</code></pre>`;
};

export default highlight;
