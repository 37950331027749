const blockEmbedPlugin = require("markdown-it-block-embed");

/**
 * Returns Markdown-it plugin for use with instance::use(plugin, options) that
 * supports detects and outputs block level embeds such as videos and custom embed services.
 *
 * 1. Section headers will be given unique identifiers for use as internal anchors.
 *    For example, markdown:
 *
 *    @[youtube](lJIrF4YjHfQ)
 *
 *    will be rendered as HTML:
 *
 *    <div class="block-embed block-embed-service-youtube">
 *        <iframe type="text/html"
 *            width="640"
 *            height="390"
 *            src="//www.youtube.com/embed/lJIrF4YjHfQ"
 *            frameborder="0"
 *            webkitallowfullscreen mozallowfullscreen allowfullscreen>
 *        </iframe>
 *    </div>
 */
export default () => ({
    plugin: blockEmbedPlugin,
});
