import sanitizeHtmlLibrary from "sanitize-html";
import logger from "../../../utils/logger";
import { performanceTracker } from "../../../utils/performance";

export const sanitizeHtml = html => {
    performanceTracker.markMarkupSanitization.start();
    const integer = "[0-9]+";
    const number = `${integer}(.${integer})*`;
    const percentage = `${number}%`;
    const units = "(?:em|pt|pc|px)";
    const length = `${number}${units}?`;

    const lenOrPer = `(?:${length}|${percentage})`;
    const positions = /^(?:static|relative|absolute|sticky|fixed)$/i;

    const familyName = "[\\w]+(?:[ -]?\\w+)*";
    const genericFont = "(?:serif|sans-serif|cursive|fantasy|monospace)";
    const lineStyle =
        "(?:none|hidden|dotted|dashed|solid|double|groove|ridge|inset|outset)";
    const lineWidth = `(?:thin|medium|thick|(${length}))`;

    const lenOrPerRE = new RegExp(`^${lenOrPer}$`, "i");
    // allow color words, and regex of 3 or 6 characters
    const colorOrHex = [
        new RegExp("^[a-z]+$", "i"),
        new RegExp("^#(?:[0-9a-f]{3}){1,2}$", "i"),
    ];

    const sanitizedHtml = sanitizeHtmlLibrary(html, {
        allowedTags: sanitizeHtmlLibrary.defaults.allowedTags.concat([
            "img",
            "input",
            "s",
            "blockquote",
            "h1",
            "h2",
            "kbd",
            "center",
            "span",
        ]),
        allowedAttributes: {
            "*": ["aria-*", "class"],
            div: ["data-reactroot", "id"],
            a: [
                {
                    name: "target",
                    values: "_blank",
                },
                "href",
                "id",
                "title",
            ],
            i: ["style"],
            img: ["src", "alt", "title"],
            h1: ["id"],
            h2: ["id"],
            h3: ["id"],
            h4: ["id"],
            h5: ["id"],
            h6: ["id"],
            ol: ["start"],
            li: ["value"],
            input: [
                {
                    name: "class",
                    values: ["inline-code"],
                },
                {
                    name: "type",
                    values: ["text"],
                },
                "readonly",
                "value",
                "size",
            ],
            th: ["style"],
            td: ["style"],
            span: ["style", "id"],
            iframe: [
                {
                    name: "type",
                    values: ["text/html"],
                },
                "src",
                "frameborder",
                "width",
                "height",
                "webkitallowfullscreen",
                "mozallowfullscreen",
                "allowfullscreen",
            ],
        },
        allowedStyles: {
            th: {
                "text-align": [/^right$/],
            },
            td: {
                "text-align": [/^right$/],
            },
            i: {
                color: colorOrHex,
            },
            span: {
                "background-color": colorOrHex,
                "border-color": colorOrHex,
                "border-radius": [
                    new RegExp(`^${lenOrPer}( ${lenOrPer}){0,3}$`, "i"),
                ],
                "border-style": [
                    new RegExp(`^${lineStyle}( ${lineStyle}){0,3}$`, "i"),
                ],
                "border-width": [
                    new RegExp(`^${lineWidth}( ${lineWidth}){0,3}$`, "i"),
                ],
                color: colorOrHex,
                "font-family": [
                    new RegExp(`^${genericFont}$`, "i"),
                    new RegExp(`^${familyName}$`, "i"),
                    new RegExp(`^(${familyName}, ?)+${genericFont}$`, "i"),
                ],
                "font-size": [
                    /^(?:xx-small|x-small|small|medium|large|x-large|xx-large)$/i,
                    /^(?:larger|smaller)$/i,
                    lenOrPerRE,
                ],
                "font-weight": [
                    new RegExp(
                        `^(?:normal|bold|bolder|lighter|(${number}))`,
                        "i"
                    ),
                ],
                "outline-color": colorOrHex,
                padding: [new RegExp(`^${lenOrPer}( ${lenOrPer}){0,3}$`, "i")],
                "padding-bottom": [lenOrPerRE],
                "padding-left": [lenOrPerRE],
                "padding-right": [lenOrPerRE],
                "padding-top": [lenOrPerRE],
                position: [positions],
                top: [
                    new RegExp(`^-?${length}$`),
                    new RegExp(`^-?${percentage}$`),
                    /^auto$/i,
                ],
                "white-space": [/^(?:normal|pre|nowrap|pre-wrap|pre-line)$/i],
            },
        },
        allowProtocolRelative: true,
        allowIframeRelativeUrls: false,
        allowedIframeHostnames: [
            "www.youtube.com",
            "player.vimeo.com",
            "vine.co",
            "prezi.com",
        ],
    });

    const createElementFromHTML = htmlString => {
        const div = document.createElement("div");
        div.innerHTML = htmlString.trim();
        return div;
    };

    const removeStyleAttribute = currentNode => {
        // We have issues comparing this attribute
        // So we will remove it for the Full STOP exception test
        // However, it will still go through sanitation

        // These are equivalent, but fails node comparison
        // <i style="text-align: right">
        // <i style="text-align: right;">
        // <i style="text-align:right">
        // <i style="text-align:right;">

        if (currentNode && currentNode.hasAttribute("style"))
            currentNode.removeAttribute("style");

        for (let n = 0; n < currentNode.childNodes.length; n++) {
            const child = currentNode.childNodes[n];

            if (child.nodeType === 1)
                // node type is element; recursively remove style attribute
                removeStyleAttribute(child);
        }
    };

    /**
  *  A requirement of AppSec is that we hard stop
  * when we find sanitation has cleaned something
  * There are no hooks in sanitize-html, so we have to compare
  * the input and output.

  * Because the sanitation cleans properties and outputs
  * corrected XHTML, we have to test using node tree equivalency
  */
    const origElem = createElementFromHTML(html);
    const sanitizedElem = createElementFromHTML(sanitizedHtml);

    /**
     * We also need to strip the CSS styles from our checks
     * because Node compare can't handle normalizing this field
     * during the comparison
     */
    removeStyleAttribute(origElem);
    removeStyleAttribute(sanitizedElem);

    if (!origElem.isEqualNode(sanitizedElem)) {
        logger.log(html);
        logger.log(sanitizedHtml);
        throw new Error("Disallowed HTML detected!  Full STOP!");
    }

    performanceTracker.markMarkupSanitization.end();

    return sanitizedHtml;
};

export default sanitizeHtml;
