import { isDevelopment } from "./helpers";

const setupLog = (type, logs) => {
    if (!isDevelopment()) return;

    console[type](logs);
};

const log = logs => setupLog("log", logs);
const info = logs => setupLog("info", logs);
const warning = logs => setupLog("warning", logs);
const debug = logs => setupLog("debug", logs);
const error = logs => setupLog("error", logs);

export default { log, info, warning, debug, error };
