import React from 'react';
import { useIntl } from 'react-intl';
import { Alert } from '@amzn/awsui-components-react';

import messages from './ErrorMessage.messages';

export const errorMessageTypes = {
    sessionError: {
        header: messages.sessionErrorHeader,
        body: messages.sessionErrorBody,
        alertType: 'error'
    },
    fatal: {
        header: messages.fatalHeader,
        body: messages.fatalBody,
    },
    notFound: {
        header: messages.noActiveLabHeader,
        body: messages.noActiveLabBody,
    },
    unauthorized: {
        header: messages.unauthorizedHeader,
        body: messages.unauthorizedBody,
    },
    accountLimit: {
        header: messages.accountLimitReachedHeader,
        body: messages.accountLimitReachedBody,
    },
};

const ErrorMessage = ({ type }) => {
    const { formatMessage } = useIntl();
    const { header, body, alertType } = errorMessageTypes[type];
    return (
        <Alert type={alertType || 'warning'} header={formatMessage(header)}>
            <p>{formatMessage(body)}</p>
        </Alert>
    );
};

export default ErrorMessage;
