import markdownItTocAndAnchor from "markdown-it-toc-and-anchor";

/**
 * Returns Markdown-it plugin for use with instance::use(plugin, options) that
 * supports a table of contents for the rendered content.
 *
 * Section headers will be given unique identifiers for use as internal anchors.
 *    For example, markdown:
 *
 *    ### My heading
 *
 *    will be rendered as HTML:
 *
 *    <h3 id="my-heading"/>
 *
 * This will be invoked during the rendering of the MarkdownContent component,
 * and the application state won't update during that time.
 * setTimeout() is used to get the setToc action out of the render context.
 *
 * @param setToc The state function to set the TOC with array returned in callback function
 */

const TableOfContents = setToc => {
    return {
        plugin: markdownItTocAndAnchor,
        options: {
            anchorLink: false,
            tocCallback: (_, tocArray) =>
                setToc(tocArray.filter(item => item.level === 2)),
        },
    };
};

export default TableOfContents;
