import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "@amzn/awsui-components-react";

import messages from "./SessionActionsBar.messages";

export const ConsoleButton = ({
    consoleAccess,
    isConsoleUrlLoading,
    sessionActive,
    refetchSession,
    globals = { window },
}) => {
    const { formatMessage } = useIntl();
    const [refetchConsoleUrlLoading, refetchConsoleUrlLoadingSet] = useState(false);
    const onClick = () => {
        refetchConsoleUrlLoadingSet(true);
        refetchSession().then(url => {
            refetchConsoleUrlLoadingSet(false)
            return globals.window.open(url, "_blank", "noopener");
        });
    };
    if (consoleAccess) {
        return (
            <Button
                icon="external"
                variant="primary"
                onClick={onClick}
                loading={!!isConsoleUrlLoading || refetchConsoleUrlLoading}
                disabled={!sessionActive}
            >
                {formatMessage(messages.openConsole)}
            </Button>
        );
    }
    return null;
};
