import { useRef } from "react";
import { useLocation } from "react-router-dom";

export const useHashScrollOnLoad = (globals) => {
    const { hash } = useLocation();
    const decodedHash = decodeURIComponent(hash)
    const hasScrolled = useRef(false);
    if (decodedHash.length && globals.document.getElementById(decodedHash.substr(1)) && !hasScrolled.current) {
        hasScrolled.current = true;
        setTimeout(() => {
            globals.location.hash = "";
            globals.document.getElementById(decodedHash.substr(1)).scrollIntoView({ behavior: 'smooth' });
        }, 300);
    }
};
