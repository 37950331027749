import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import { AppLayout, Button } from "@amzn/awsui-components-react";
import _get from "lodash/get";
import _pick from "lodash/pick";

import {
    LabHeader,
    LabContent,
    Sidebar,
    ConsoleSecrets,
    TableOfContents,
    SessionQuery,
    ContentMessage,
} from "..";
import { useHashScrollOnLoad } from "../../utils/hooks";
import messages from "./LabWrapper.messages";
import "./LabWrapper.scss";

const LabWrapper = ({ GetLab, GetBlueprint, GetMarkdown }) => {
    const { formatMessage } = useIntl();
    useHashScrollOnLoad(window);
    const [tocElements, setToc] = useState([]);
    const [isInvoking, setIsInvoking] = useState(false);
    const bodyRef = useRef(null);
    const contentRef = useRef(null);

    const { labId, sessionReady, sessionLoading, sessionActive, sessionError, sessionEnded } =
        GetLab.data;

    const commonBtnProps = {
        sessionReady,
        sessionLoading,
        sessionActive,
        sessionEnded
    };

    const xhrErrorStatuses = [GetLab, GetBlueprint, GetMarkdown]
        .map((data) => _get(data.error, "status"))
        .filter((status) => status >= 0);
    const jumpToMain = () => {
        contentRef.current.setAttribute("tabindex", 0);
        contentRef.current.focus({ focusVisible: false });
    };
    return (
        <>
            <div data-testid="jump-to-main">
                <Button variant="link" onClick={jumpToMain}>
                    {formatMessage(messages.jumpToMain)}
                </Button>
            </div>
            <SessionQuery labId={labId} setIsInvoking={setIsInvoking}>
                <LabHeader
                    buttonProps={commonBtnProps}
                    sessionExpired={GetLab.data.sessionExpired}
                    {..._pick(GetBlueprint.data, [
                        "title",
                        "description",
                        "consoleAccess",
                    ])}
                />
            </SessionQuery>
            <AppLayout
                disableBodyScroll
                stickyNotifications={true}
                toolsHide
                headerSelector=".Header"
                navigationHide={false}
                navigation={
                    <Sidebar>
                        <ConsoleSecrets {...GetLab.data.parsedSecrets} />
                        <TableOfContents tocElements={tocElements} />
                    </Sidebar>
                }
                notifications={
                    <div aria-live="polite" aria-atomic="true">
                        <ContentMessage
                            sessionLoading={sessionLoading}
                            sessionReady={sessionReady}
                            isInvoking={isInvoking}
                            xhrErrorStatuses={xhrErrorStatuses}
                            sessionError={sessionError}
                        />
                    </div>
                }
                labels={{
                    navigation: formatMessage(messages.navigationWrapper),
                    navigationToggle: formatMessage(messages.openNavigation),
                    navigationClose: formatMessage(messages.closeNavigation),
                    notifications: formatMessage(messages.notificationsWrapper),
                }}
                content={
                    <LabContent
                        xhrErrorStatuses={xhrErrorStatuses}
                        markdown={GetMarkdown.data.body}
                        setToc={setToc}
                        ref={bodyRef}
                        contentRef={contentRef}
                    />
                }
            ></AppLayout>
        </>
    );
};

export default LabWrapper;
