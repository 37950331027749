import { defineMessages } from "react-intl.macro";

export default defineMessages({
    loader: {
        id: "status.loader",
        description:
            "Displayed in a full screen page with a spinner to indicate the user needs to wait until resources are loaded",
        defaultMessage: "Loading lab, please wait...",
    },
});
