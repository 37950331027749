import React from "react";
import { useIntl } from "react-intl";
import { Spinner } from "@amzn/awsui-components-react";

import messages from "./Loader.messages";
import "./Loader.scss";

const Loader = ({ visible, hasError, fallback, children }) => {
    const { formatMessage } = useIntl();
    if (hasError) {
        return fallback ? fallback() : null;
    }
    return !visible ? (
        children
    ) : (
        <div className="loader" data-testid="loader">
            <h1>{formatMessage(messages.loader)}</h1>
            <Spinner size="large" />
        </div>
    );
};

export default Loader;
