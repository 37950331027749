import { renderingRule } from "../utils/markdown.plugin.utils";

/**
 * Markdown-it will render anchor tags with target attribute set to "_blank":
 *
 * [AMZN](https://amazon.com) => <a href="https://amazon.com" target="_blank">AMZN</a>
 *
 * Links in markdown currently replace the instructions tab, which is confusing for students.
 */
export const ExternalizeLinks = renderingRule(
    "link_open",
    ({ origRender, tokens, idx }) => {
        // Add new attribute
        tokens[idx].attrPush(["target", "_blank"]);
        return origRender();
    }
);

export default [ExternalizeLinks];
