import React from "react";
import { useIntl } from "react-intl";
import { Button, Spinner } from "@amzn/awsui-components-react";

import messages from "./SessionActionsBar.messages";
import { closeTab } from "../../utils/helpers";

export const SessionButton = ({
    invokeStartSession,
    confirmEndSessionAction,
    sessionReady,
    sessionLoading,
    sessionActive,
    sessionEnded,
    isInvoking,
    globals = window
}) => {
    const { formatMessage } = useIntl();
    const message = sessionActive || sessionEnded || sessionLoading ? messages.endSession : messages.startSession;
    const onClick = (event) => {
        if (sessionLoading || sessionActive) {
            confirmEndSessionAction(true);
        } else if (sessionReady) {
            invokeStartSession(event);
        } else {
            closeTab(globals);
        }
    };
    return (
        <>
            {sessionLoading ? <Spinner /> : ' '}{' '}
            <Button
                variant={sessionReady ? "primary" : "normal"}
                loading={!!isInvoking}
                onClick={onClick}
            >
                {formatMessage(message)}
            </Button>
        </>
    );
};
