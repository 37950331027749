import BlockEmbed from "./BlockEmbed";
import ExternalizeLinks from "./ExternalizeLinks";
import RenderInlineCode from "./RenderInlineCode";
import ListItemNumbering from "./ListItemNumbering";
import RenderCodeBlock from "./RenderCodeBlock";
import SanitizeHTML from "./SanitizeHTML";
import StyleLists from "./StyleLists";
import TableOfContents from "./TableOfContents";
import InlineComment from "./InlineComment";

export const rendererPlugins = [
    ...ExternalizeLinks,
    ...RenderInlineCode,
    ...ListItemNumbering,
    ...RenderCodeBlock,
    ...SanitizeHTML,
    ...StyleLists,
];

export const usePlugins = [TableOfContents, BlockEmbed, InlineComment];
