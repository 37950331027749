import { defineMessages } from "react-intl.macro";

export default defineMessages({
    navigationWrapper: {
        id: "LabWrapper.navigationWrapper",
        description: "Text to describe the navigation",
        defaultMessage: "Page navigation and console information",
    },
    openNavigation: {
        id: "LabWrapper.openNavigation",
        description: "Label for opening the navigation",
        defaultMessage: "Open sidebar",
    },
    closeNavigation: {
        id: "LabWrapper.closeNavigation",
        description: "Label for closing the navigation",
        defaultMessage: "Close sidebar",
    },
    notificationsWrapper: {
        id: "LabWrapper.notificationsWrapper",
        description: "Text to describe the notifications area",
        defaultMessage: "Lab notifications",
    },
    jumpToMain: {
        id: "LabWrapper.jumpToMain",
        defaultMessage: "Jump to main content",
    }
});
