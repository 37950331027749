import {
    fetchRequest,
    fetchError,
    fetchSuccess,
} from "../actions/fetch.actions";
import { mockDataMode } from "./helpers";
import { AppError, FETCH_ERROR_NAME } from "./errors";
import logger from "./logger";
import { retry, waitFor } from "./promiseUtils";

const is500Response = error => error.status >= 500;

export class FetchClientBuilder {
    globals = {};
    dispatch = () => {};
    apiDefinitions = {};
    mockHistory = {};

    constructor(globals, locale) {
        this.globals = globals;
        this.locale = locale;
    }

    addDispatch(dispatch) {
        this.dispatch = dispatch;
        return this;
    }

    addApiDefinitions(apiDefinitions) {
        this.apiDefinitions = apiDefinitions;
        return this;
    }

    fetch = name => async ({
        params = {},
        delay = 0,
        dataParser,
        idToken,
    } = {}) => {
        const { path = "", method = "GET", headers = {}, body } = params;

        const { baseUrl, onSuccess } = this.apiDefinitions[name];

        this.dispatch(fetchRequest(name));

        const url = this.constructUrl(name, baseUrl + path);
        const preparedBody = body ? JSON.stringify(body) : undefined;

        const request = {
            method,
            body: preparedBody,
            headers: {
                Authorization: idToken,
                "Content-Type":
                    method === "POST"
                        ? "application/x-www-form-urlencoded"
                        : "application/json",
                ...headers,
            },
        };

        // cannot pass this header since GetMarkdown request already has a signed url
        if (name === 'GetMarkdown') {
            delete request.headers.Authorization;
        }

        try {
            const data = await retry(
                () =>
                    this.makeRequest({
                        url,
                        request,
                        onSuccess,
                        delay,
                    }),
                { retryPredicate: is500Response }
            );

            const parsedData =
                typeof dataParser === "function" ? dataParser(data) : data;

            this.dispatch(fetchSuccess(name, parsedData));
            return { data: parsedData };
        } catch (err) {
            logger.error(`Fetch catch: ${err}`);
            this.dispatch(fetchError(name, err));
            return { error: err };
        }
    };

    constructUrl = (name, path) => {
        if (!mockDataMode()) return path;
        if (this.mockHistory[name] === undefined) this.mockHistory[name] = -1;
        this.mockHistory[name]++;
        return `${path}/${this.mockHistory[name]}`;
    };

    async makeRequest({
        url,
        request,
        credentials,
        onSuccess,
        delay,
    }) {
        await waitFor(delay);
        const res = await this.globals.fetch(url, request);
        if (!res.ok) {
            throw new AppError(
                `Request to ${url} rejected with status: ${res.status}`,
                {
                    name: FETCH_ERROR_NAME,
                    status: res.status,
                }
            );
        }
        return await (onSuccess ? onSuccess(res) : res.json());
    }
}
