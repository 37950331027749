import React from "react";
import { prop } from "ramda";

import InlineCodeBlock from "../components/InlineCodeBlock";
import { renderingRule, renderReact } from "../utils/markdown.plugin.utils";

/**
 * Markdown-it will render <code>...</code> blocks for inline code surrounded by backticks:
 *
 * Here is `something` to copy. => <p>Here is <code>something</code> to copy.</p>
 *
 * Inline code is used in cases where the student is intended to copy the content to their
 * clipboard. Since user-select is disabled for the overall content, we need to do something
 * to enable copying of inline code. Styling the <code> element including user-select: copy
 * is not an ideal solution, since double/triple clicking on the text and copying will
 * result in text outside of the <code> block being copied, depending on the browser. For
 * this reason, we override the rendering to output a custom component.
 */
export const RenderInlineCode = renderingRule(
    "code_inline",
    renderReact(({ content }) => (
        <InlineCodeBlock content={prop("content", content)} />
    ))
);

export default [RenderInlineCode];
