import React from "react";
import { useIntl } from 'react-intl';

import "./Timer.scss";
import messages from './Timer.messages';

const MILLIS_IN_SECOND = 1000;
const MILLIS_IN_MINUTE = 1000 * 60;
const MILLIS_IN_HOUR = MILLIS_IN_MINUTE * 60;

export const repeatString = (string, count) => {
    let repeatedString = "";
    for (let i = 0; i < count; i++) {
        repeatedString += string;
    }
    return repeatedString;
};

export const leftPadNumbers = (number, digits) =>
    repeatString("0", digits).substring(0, digits - ("" + number).length) +
    number;

export const displayTime = ({ hours = "00", minutes = "00", seconds = "00" }) =>
    `${hours}:${minutes}:${seconds}`;

export const convertToDisplayTime = time => {
    const props = {
        hours: leftPadNumbers(Math.floor(time / MILLIS_IN_HOUR) || "00", 2),
        minutes: leftPadNumbers(
            Math.floor((time % MILLIS_IN_HOUR) / MILLIS_IN_MINUTE),
            2
        ),
        seconds: leftPadNumbers(
            Math.floor((time % MILLIS_IN_MINUTE) / MILLIS_IN_SECOND),
            2
        ),
    };

    props.displayTime = displayTime(props); // This will need localization, but will be tricky
    return props;
};

const Timer = ({ time }) => {
    const { formatMessage } = useIntl();

    const timeProps = convertToDisplayTime(time);
    const displayTimeLabel = formatMessage(messages.timerLabel, {
        hours: Number(timeProps.hours),
        minutes: Number(timeProps.minutes),
    });

    return (
        <span className="timer">
            <div
                role="timer"
                aria-live="polite"
                aria-label={displayTimeLabel}
            />
            {timeProps.displayTime}
        </span>
    );
};

export default Timer;
