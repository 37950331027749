import Analytics from "@aws-amplify/analytics";
import _camelCase from "lodash/camelCase";
import _upperFirst from "lodash/upperFirst";

import { statlerProviderName, statlerUIAnalyticsProvider } from "./Analytics";
import { msToSeconds } from "./helpers";
import logger from "./logger";

const APP_START = "app-ready-start";

// NOTE: this metric is marked for deletion, this is providing no value
export const CONFIG_TIME = {
    name: "config-complete-time",
    start: APP_START,
    end: "config-time-end",
};

export const LOADING_SCREEN = {
    name: "total-loading-screen-time",
    start: "loading-screen-start",
    end: "loading-screen-end",
};

// NOTE: this metric is marked for deletion, it will be replaced with the api timings
// keeping it around for a 1:1 comparison with SessionUI
export const MARKDOWN_REQUEST = {
    name: "markdown-request-time",
    start: "markdown-request-start",
    end: "markdown-request-end",
};

export const MARKDOWN_CONVERT = {
    name: "markdown-convert-time",
    start: "markdown-convert-start",
    end: "markdown-convert-end",
};

export const MARKUP_SANITIZATION = {
    name: "markup-sanitization-time",
    start: "markup-sanitization-start",
    end: "markup-sanitization-end",
};

export const MARKUP_RENDER = {
    name: "markup-render-time",
    start: "markup-render-start",
    end: "markup-render-end",
};

export const APP_READY = {
    name: "app-ready-time",
    start: APP_START,
    end: "app-ready-end",
};

// NOTE: this metric is marked for deletion, will be replaced by beaker-analytics
export const FIRST_PAINT = {
    name: "first-paint-time",
    start: APP_START,
    end: "first-paint-end",
};

export const PROVISIONING_TIME = {
    name: "total-time-provisioning",
    start: "provisioning-start",
    end: "provisioning-end",
};

export const START_LAB_TO_CONSOLE = {
    name: "total-time-start-lab-to-console",
    start: "start-lab-to-console-start",
    end: "start-lab-to-end",
};

export const performanceTracker = {
    recordings: {},
    record(event) {
        this.recordings[event.name] = this.recordings[event.name] || 0;
        if (this.recordings[event.name]) return;
        Analytics.record(
            {
                ...event,
                env: {
                    url: window.location.href,
                    userAgent: window.navigator && window.navigator.userAgent,
                },
                attributes: {
                    labTitle: this.labTitle(),
                },
            },
            statlerProviderName
        );
        this.recordings[event.name]++;
    },
    labTitle() {
        return statlerUIAnalyticsProvider.labTitle;
    },
    measureFrame({ name, start, end }) {
        performance.measure(name, start, end);
    },
    getDurationInSeconds(name) {
        const performanceMeasurements = performance.getEntriesByName(name);

        return performanceMeasurements.length > 0 &&
            performanceMeasurements[0].duration
            ? msToSeconds(performanceMeasurements[0].duration)
            : "no measurements added";
    },
    hasMarked(name) {
        return !!performance
            .getEntriesByType("mark")
            .find((mark) => mark.name === name);
    },
    getPerformanceFrame(frame) {
        try {
            const [{ startTime: start }, { startTime: end }] = [
                frame.start,
                frame.end,
            ].map((name) => performance.getEntriesByName(name)[0]);
            return { start: msToSeconds(start), end: msToSeconds(end) };
        } catch (err) {
            logger.error(
                `Error getting the performance frame for ${frame}`,
                err
            );
            return "no measurements added";
        }
    },

    recordAppReady() {
        const timeToConfig = this.getDurationInSeconds(CONFIG_TIME.name);
        const timeToFirstPaint = this.getDurationInSeconds(FIRST_PAINT.name);
        const timeToGetMarkdown = this.getDurationInSeconds(
            MARKDOWN_REQUEST.name
        );
        const totalLoadingScreenTime = this.getDurationInSeconds(
            LOADING_SCREEN.name
        );
        const timeToConvertMarkdown = this.getDurationInSeconds(
            MARKDOWN_CONVERT.name
        );
        const timeToSanitizeMarkup = this.getDurationInSeconds(
            MARKUP_SANITIZATION.name
        );
        const timeToRenderMarkup = this.getDurationInSeconds(
            MARKUP_RENDER.name
        );
        const timeToAppReady = this.getDurationInSeconds(APP_READY.name);

        const metrics = {
            timeToConfig,
            timeToFirstPaint,
            timeToGetMarkdown,
            totalLoadingScreenTime,
            timeToConvertMarkdown,
            timeToSanitizeMarkup,
            timeToRenderMarkup,
            timeToAppReady,
        };

        this.record({
            name: APP_READY.name,
            type: "AppMetrics",
            metrics,
        });

        this.recordApiTimings();
    },

    recordApiTimings() {
        const apiTimings = performance
            .getEntriesByType("resource")
            .filter((item) => item.initiatorType === "fetch")
            .reduce((acc, { name, startTime, responseEnd }) => {
                let key;
                if (name.includes("GetLab")) {
                    key = "GetLab";
                } else if (name.includes("GetBlueprint")) {
                    key = "GetBlueprint";
                } else if (name.includes("contentresources")) {
                    key = "GetMarkdown";
                } else {
                    return acc;
                }
                return {
                    ...acc,
                    [key]: {
                        start: msToSeconds(startTime),
                        end: msToSeconds(responseEnd),
                    },
                };
            }, {});

        const getMarkdownFrame = this.getPerformanceFrame(MARKDOWN_REQUEST);
        const loadingScreenFrame = this.getPerformanceFrame(LOADING_SCREEN);
        const appReadyFrame = this.getPerformanceFrame(APP_READY);
        const markdownConvertFrame = this.getPerformanceFrame(MARKDOWN_CONVERT);
        const markupSanitizationFrame = this.getPerformanceFrame(
            MARKUP_SANITIZATION
        );
        const markupRenderFrame = this.getPerformanceFrame(MARKUP_RENDER);

        const metrics = {
            ...apiTimings,
            getMarkdownFrame,
            loadingScreenFrame,
            appReadyFrame,
            markdownConvertFrame,
            markupSanitizationFrame,
            markupRenderFrame,
        };

        this.record({
            name: "app.timings",
            type: "AppTiming",
            metrics,
        });
    },

    recordStartLabToConsole() {
        const startLabToConsoleUrl = this.getDurationInSeconds(
            START_LAB_TO_CONSOLE.name
        );

        this.record({
            name: START_LAB_TO_CONSOLE.name,
            type: "AppMetrics",
            metrics: {
                startLabToConsoleUrl,
            },
        });
    },

    recordProvisioningTime() {
        const totalProvisioningTime = this.getDurationInSeconds(
            PROVISIONING_TIME.name
        );

        this.record({
            name: PROVISIONING_TIME.name,
            type: "AppMetrics",
            metrics: {
                totalProvisioningTime,
            },
        });
    },
};

// iterate through all the options to add properties to performanceTracker to create methods
// for marking the starts and ends for each
// e.g.
//     performanceTracker.hasMarkedConfigTime.start()  => boolean
//     performanceTracker.hasMarkedConfigTime.end() => boolean
//     performanceTracker.markConfigTime.start() => undefined
//     performanceTracker.markConfigTime.end() => undefined
[
    ["CONFIG_TIME", CONFIG_TIME],
    ["LOADING_SCREEN", LOADING_SCREEN],
    ["MARKDOWN_REQUEST", MARKDOWN_REQUEST],
    ["MARKDOWN_CONVERT", MARKDOWN_CONVERT],
    ["MARKUP_SANITIZATION", MARKUP_SANITIZATION],
    ["MARKUP_RENDER", MARKUP_RENDER],
    ["APP_READY", APP_READY],
    ["FIRST_PAINT", FIRST_PAINT],
    ["PROVISIONING_TIME", PROVISIONING_TIME],
    ["START_LAB_TO_CONSOLE", START_LAB_TO_CONSOLE],
].forEach(([name, val]) => {
    const formattedName = _upperFirst(_camelCase(name));
    performanceTracker[`hasMarked${formattedName}`] = {
        start: () => performanceTracker.hasMarked(val.start),
        end: () => performanceTracker.hasMarked(val.end),
    };
    performanceTracker[`mark${formattedName}`] = {
        start: () => {
            if (!performanceTracker[`hasMarked${formattedName}`].start()) {
                performance.mark(val.start);
            }
        },
        end: () => {
            if (!performanceTracker[`hasMarked${formattedName}`].end()) {
                performance.mark(val.end);
                if (performanceTracker[`hasMarked${formattedName}`].start()) {
                    performanceTracker.measureFrame(val);
                }
            }
        },
    };
});
