import React, { useEffect, Fragment, createRef } from "react";
import { useIntl } from "react-intl";

import { copyToClipboard, downloadText } from "../../utils/helpers";
import { useQuery } from "../../utils/hooks";
import messages from "./ConsoleSecrets.messages";
import "./ConsoleSecrets.scss";

const formatCfnLabel = name => `cfnExports${name}`;

const getMissingTranslations = labels => {
    return labels.reduce((translations, label) => {
        if (!messages[formatCfnLabel(label)]) {
            return [...translations, label];
        }
        return translations;
    });
};

export const SUPPORTED_KEY_PAIR_FORMATS = {
    "Ec2KeyPair-PEM": {
        downloadLabel: messages.consoleSecretsEC2DownloadPEM,
        downloadExtension: "pem",
    },
    "Ec2KeyPair-PPK": {
        downloadLabel: messages.consoleSecretsEC2DownloadPPK,
        downloadExtension: "ppk",
    },
};

const ConsoleSecrets = ({ keyPairs, cfnExports }) => {
    const { formatMessage } = useIntl();
    const { fetch } = useQuery("SendClientLogs");

    const downloadFromText = key => () => {
        downloadText(
            SUPPORTED_KEY_PAIR_FORMATS[key].downloadExtension,
            keyPairs[key]
        );
    };

    useEffect(() => {
        if (!cfnExports) return;
        const missingTranslations = getMissingTranslations(
            Object.keys(cfnExports)
        );

        fetch({
            params: { body: { missingTranslations }, method: "POST" },
            skipAuth: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cfnExports]);

    if (!keyPairs && !cfnExports) return null;

    return (
        <div className="console-secrets">
            <h3 className="console-secrets__title">
                {formatMessage(messages.consoleSecretsTitle)}
            </h3>
            <div className="console-secrets__body">
                {keyPairs ? (
                    <SecretsSection
                        label={formatMessage(
                            messages.consoleSecretsEC2KeysTitle
                        )}
                    >
                        <ul className="key-pair-list">
                            {Object.keys(keyPairs).map(key => (
                                <li key={key} className="key-pair-list__item">
                                    <button onClick={downloadFromText(key)}>
                                        {formatMessage(
                                            SUPPORTED_KEY_PAIR_FORMATS[key]
                                                .downloadLabel
                                        )}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </SecretsSection>
                ) : null}

                {cfnExports ? (
                    <ul className="secrets-list">
                        {Object.keys(cfnExports).map(name => (
                            <li className="secrets-list__item" key={name}>
                                <CopyExports
                                    name={
                                        messages[formatCfnLabel(name)]
                                            ? formatMessage(
                                                  messages[formatCfnLabel(name)]
                                              )
                                            : name
                                    }
                                    value={cfnExports[name]}
                                />
                            </li>
                        ))}
                    </ul>
                ) : null}
            </div>
        </div>
    );
};

const SecretsSection = ({ label, children }) => (
    <Fragment>
        <span className="secrets-section__label">{label}</span>
        {children}
    </Fragment>
);

const CopyExports = ({ name, value }) => {
    const inputRef = createRef();

    const selectTextInput = () => inputRef.current.select();
    const copyTextToClipboard = () => {
        copyToClipboard({ globals: window, value });
        selectTextInput();
    };

    return (
        <SecretsSection label={name}>
            <div className="copy-exports__body">
                <input
                    className="copy-exports__body__input"
                    value={value}
                    type="text"
                    onClick={selectTextInput}
                    ref={inputRef}
                    readOnly
                />
                <button
                    onClick={copyTextToClipboard}
                    className="copy-exports__body__clipboard"
                >
                    <span className="is-visually-hidden">{`Copy ${name} secret to clipboard`}</span>
                </button>
            </div>
        </SecretsSection>
    );
};

export default ConsoleSecrets;
