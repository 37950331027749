import {
    renderingRule,
    renderIfTokensMatch,
    setAttrs,
} from "../utils/markdown.plugin.utils";

const EXPLICIT_NUMBER_LIST_ITEM_PATTERN = /(^\s*\[([-+]?[0-9]+)]\s*)+/;
/**
 * Numbering handler for explicit numbering: a backwards compatible syntax for output
 * of @brahmsan tool at http://mdc.awstc.com/MDNumberingCorrecter.html.
 *
 * Invokes callback when listItemText matches:
 *
 * [<a number>] some text...
 *
 * The argument to callback would be { number: <a number>, listItemText: 'some text...' }
 *
 * @param { listItemText: string, env: Object }  input
 *     The text of an ordered list item and the Markdown-it rendering environment object.
 * @param {function ({ number: number, listItemText: string }) callback
 *     Function that is invoked iff handler applies to the input text.
 */
const ExplicitNumberNumberingHandler = ({ listItemText }, callback) => {
    const groups = EXPLICIT_NUMBER_LIST_ITEM_PATTERN.exec(listItemText);
    if (groups) {
        callback({
            number: parseInt(groups[2]),
            listItemText: listItemText.substr(groups[1].length),
        });
    }
};

const IDENTIFIER_LIST_ITEM_PATTERN = /(^\s*\[(\w*)]\s*)+/;
/**
 * Numbering handler for identifier-based numbering, a new syntax that requires less
 * configuration than the explicit numbering in the @brahmsan tool output.
 *
 * Invokes callback when listItemText matches:
 *
 * [<an identifier or no text>] some text...
 *
 * The argument to callback would be {
 *     number: <next number for identifier, starting at 1>, listItemText: 'some text...'
 * }
 *
 * @param { listItemText: string, env: Object }  input
 *     The text of an ordered list item and the Markdown-it rendering environment object.
 * @param {function ({ number: number, listItemText: string }) callback
 *     Function that is invoked iff handler applies to the input text.
 */
const IdentifierNumberingHandler = ({ listItemText, env }, callback) => {
    const groups = IDENTIFIER_LIST_ITEM_PATTERN.exec(listItemText);
    if (groups) {
        const identifier = groups[2];
        if (!env.listItemsNumbers) {
            env.listItemsNumbers = {};
        }
        if (!env.listItemsNumbers[identifier]) {
            env.listItemsNumbers[identifier] = 1;
        }
        callback({
            number: env.listItemsNumbers[identifier]++,
            listItemText: listItemText.substr(groups[1].length),
        });
    }
};

const DOUBLE_BRACKETED_IDENTIFIER_LIST_ITEM_PATTERN = /^\s*\[\s*(\[.*])\s*](.*)/;
/**
 * Numbering handler to support case where author wants to display text beginning
 * with text in brackets in an ordered list item without using any of the syntax rules
 * above.
 *
 * We do not need any special handling when the bracketed text contains spaces,
 * since IdentifierNumberingHandler only captures sequences or word characters. If
 * the text the author wants displayed in brackets is a single word, we let them
 * enclose it in a second set of brackets and render the text in a single set of
 * brackets.
 *
 * Another approach could be to have them escape the first bracket, but they would need to
 * escape the bracket twice since a single \[ would be eaten before the string gets here.
 * The hope is that double brackets are easier to explain/remember than double-backslashes.
 *
 * Invokes callback when listItemText matches:
 *
 * 0. [[somethingIWantDisplayedInBrackets]] some text...
 *
 * The argument to callback would be {
 *     listItemText: [somethingIWantDisplayedInBrackets] some text...
 * }
 *
 * @param { listItemText: string, env: Object }  input
 *     The text of an ordered list item and the Markdown-it rendering environment object.
 * @param {function ({ number: number, listItemText: string }) callback
 *     Function that is invoked iff handler applies to the input text.
 */
const DisplayBracketedTextHandler = ({ listItemText }, callback) => {
    const groups = DOUBLE_BRACKETED_IDENTIFIER_LIST_ITEM_PATTERN.exec(
        listItemText
    );
    if (groups) {
        callback({
            listItemText: groups[1] + groups[2],
        });
    }
};

const NUMBERING_HANDLERS = [
    ExplicitNumberNumberingHandler,
    IdentifierNumberingHandler,
    DisplayBracketedTextHandler,
];

/**
 * Markdown-it plugin to adjust the number displayed for ordered list items based on
 * a convention to support ascending steps in documents where list items are not all
 * contained within a single list.
 *
 * See the individual handlers above for the syntax that is supported. Each handler
 * is invoked in the order listed in NUMBERING_HANDLERS, and the first with modifications
 * to make to the list item has its changes applied.
 *
 * @param {Object} renderer    Markdown-it Renderer object to which a rendering rule will be added.
 *                             list_item elements will be enhanced to:
 *
 *                             1) display an appropriate number if one of the square brackets sytax is used
 *
 *                             2) to remove the specification of the number in square brackets at the beginning
 *                                of their inline text.
 */
export const NumberOrderedListItem = renderingRule(
    "list_item_open",
    renderIfTokensMatch(
        [
            { markup: "." }, // to detect ordered list; would be markup: '-' for unordered list
            { type: "paragraph_open" },
            { type: "inline", children: [{ type: "text" }] },
        ],
        ({ origRender, tokens, idx, env }) => {
            const token = tokens[idx];
            const textToken = tokens[idx + 2].children[0];

            NUMBERING_HANDLERS.some(handler => {
                let handled = false;
                handler(
                    { listItemText: textToken.content, env },
                    ({ number, listItemText }) => {
                        handled = true;

                        // 1) Set the 'value' attr of the token to the number desired by the handler
                        if (number !== undefined) {
                            setAttrs(token, { value: number });
                        }

                        // 2) Replace the text content with the text desired by the handler
                        textToken.content = listItemText;
                    }
                );
                return handled;
            });

            return origRender();
        }
    )
);

export default [NumberOrderedListItem];
