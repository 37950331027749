import {
    FETCH_REQUEST,
    FETCH_ERROR,
    FETCH_SUCCESS,
    CLEAR_DATA,
} from "../../actions/fetch.actions";
import { nowTime } from "../../utils/helpers";

const fetcherReducer = (state, action) => {
    const { api, type } = action;
    switch (type) {
        case CLEAR_DATA:
            return {
                ...state,
                [api]: {
                    ...(state[api] || {}),
                    data: {},
                    lastReceived: undefined,
                    error: null,
                    loading: false,
                },
            };
        case FETCH_REQUEST:
            return {
                ...state,
                [api]: {
                    ...(state[api] || {}),
                    loading: true,
                    error: null,
                },
            };
        case FETCH_ERROR:
            return {
                ...state,
                [api]: {
                    ...(state[api] || {}),
                    loading: false,
                    error: action.error,
                    lastReceived: nowTime(),
                },
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                [api]: {
                    ...(state[api] || {}),
                    loading: false,
                    data: action.data,
                    lastReceived: nowTime(),
                },
            };
        default:
            return state;
    }
};

export default fetcherReducer;
