import { v4 as uuidv4 } from 'uuid';
import { getFID, getFCP } from 'web-vitals';
import environment from '../.config/environment';

export const JS_ERROR_TYPE = 'ERROR';

const getProviderInfo = (globals) => {
    let oidcInfo = null;
    const { authorizer: { issuer, client_id } } = environment(globals);
    try {
        const key = `oidc.user:${issuer}:${client_id}`;
        const raw = globals.sessionStorage.getItem(key);
        oidcInfo = JSON.parse(raw);
        return oidcInfo?.profile || {};
    } catch (err) {
        return {};
    }
};

const sanitizeName = (obj) => {
    let name = obj.name || (obj.constructor && obj.constructor.name);

    if (name && name.includes('Error')) {
        return `_error.${name.replace('Error', '').toLowerCase()}`;
    }
    return name;
};

const convertToSeconds = (num) => num / 1000;


export class StatlerAnalyticsProvider {
    category;
    providerName;
    clientLoggingUUIDValue;
    clientLoggingUUIDKey;
    clientLoggingUrl;
    classroomId;

    constructor({
        category,
        providerName,
        clientLoggingUUIDValue,
        clientLoggingUUIDKey,
        clientLoggingUrl,
        globals,
    }) {
        this.category = category || 'Analytics';
        this.providerName = providerName;
        this.clientLoggingUUIDValue = clientLoggingUUIDValue;
        this.clientLoggingUUIDKey = clientLoggingUUIDKey;
        this.clientLoggingUrl = clientLoggingUrl;
        this.globals = globals || window;
    }

    record(params) {
        const { event } = params;
        let normalizedEvent = event;

        if (event.type && event.type.toUpperCase() === JS_ERROR_TYPE) {
            normalizedEvent = this.getErrorEvent(event);
        }

        const providerInfo = getProviderInfo(this.globals);
        const { hat_id, public_provider_name } = providerInfo;
        const data = {
            ...normalizedEvent,
            name: sanitizeName(event.error || event),
            userId: hat_id,
            sessionId: this.anonymousId(),
            provider: public_provider_name,
            classroomId: this.classroomId
        };

        const options = {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        return this.globals
            .fetch(this.clientLoggingUrl, options)
            .then(res => res.ok)
            .catch(err => {
                console.log('err', err); // eslint-disable-line no-console
                return false;
            });
    }

    getErrorEvent = ({
        message,
        type = JS_ERROR_TYPE,
        attributes,
    }) => {
        const errorEvent = {
            message,
            type,
            env: {
                url: this.globals.location?.href,
                userAgent: this.globals.navigator?.userAgent,
            },
            attributes,
        };

        return errorEvent;
    };

    anonymousId() {
        let id = this.clientLoggingUUIDValue;

        if (id) {
            return id;
        } else if (this.globals.sessionStorage.getItem(this.clientLoggingUUIDKey)) {
            this.clientLoggingUUIDValue =
                this.globals.sessionStorage.getItem(this.clientLoggingUUIDKey);
            return this.clientLoggingUUIDValue;
        } else {
            const newId = uuidv4();
            this.clientLoggingUUIDValue = newId;
            this.globals.sessionStorage.setItem(this.clientLoggingUUIDKey, newId);
            return this.clientLoggingUUIDValue;
        }
    }

    configure(config) {
        return config;
    }

    getCategory() {
        return this.category;
    }

    getProviderName() {
        return this.providerName;
    }

    addEndpoint(endpoint) {
        this.clientLoggingUrl = endpoint;
    }

    addTitle(title) {
        this.labTitle = title;
    }

    addMetadata({ classroomId, sessionId }) {
        this.classroomId = classroomId;
        this.clientLoggingUUIDValue = sessionId;
    }
}

export const trackPerformanceMetrics = ({ Analytics, providerName }) => {
    const {
        domContentLoadedEventStart = 0,
        domInteractive = 0,
        domLoading = 0,
        fetchStart = 0,
        responseEnd = 0,
        responseStart = 0,
        requestStart = 0,
    } = performance?.timing || {};

    const metrics = {
        interactive: convertToSeconds(domInteractive - domLoading),
        contentLoaded: convertToSeconds(domContentLoadedEventStart - domLoading),
        ttfb: convertToSeconds(responseStart - requestStart),
        latency: convertToSeconds(responseEnd - fetchStart),
        firstContentfulPaint: 0,
    };

    getFCP(metric => {
        metrics.firstContentfulPaint = convertToSeconds(metric.value);
        Analytics.record(
            {
                name: '_performance.timing',
                type: 'AppMetrics',
                metrics,
                env: {
                    url: window.location.href,
                    userAgent: window.navigator && window.navigator.userAgent,
                },
            },
            providerName,
        );
    });

    getFID(metric => {
        Analytics.record(
            {
                name: '_performance.timing',
                type: 'AppMetrics',
                metrics: { firstInputDelay: convertToSeconds(metric.value) },
                env: {
                    url: window.location.href,
                    userAgent: window.navigator && window.navigator.userAgent,
                },
            },
            providerName,
        );
    });
};
