import React, { createRef } from "react";
import { Icon, Popover, Button } from "@amzn/awsui-components-react";

import { copyToClipboard } from "../../../utils/helpers";
import "./InlineCodeBlock.scss";

const InlineCodeBlock = ({ content }) => {
    const copyButtonRef = createRef();

    return (
        <span className="awsui-util-copy-text">
            <Popover
                size="small"
                position="top"
                triggerType="custom"
                dismissButton={false}
                content={
                    <span className="awsui-util-status-positive">
                        <Icon variant="success" name="status-positive" /> Copied
                    </span>
                }
            >
                <Button
                    icon="copy"
                    variant="icon"
                    label="Copy Inline Code"
                    ref={copyButtonRef}
                    onClick={() => {
                        copyToClipboard({
                            globals: window,
                            value: content,
                        });
                        copyButtonRef.current.focus();
                    }}
                />
            </Popover>
            <input
                readOnly
                className="InlineCode__content"
                value={content}
                size={content.length}
                aria-label="Inline Code"
                onFocus={e => e.target.select()}
            />
        </span>
    );
};

export default InlineCodeBlock;
