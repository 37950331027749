import Analytics from "@aws-amplify/analytics";
import {
    StatlerAnalyticsProvider,
    trackPerformanceMetrics,
} from "./BeakerAnalyticsProvider";

export const CLIENT_LOG_KEY = "clientLoggingUUID";
export const statlerProviderName = "StatlerAnalyticsProvider";

export const statlerUIAnalyticsProvider = new StatlerAnalyticsProvider({
    category: "Analytics",
    providerName: statlerProviderName,
    clientLoggingUUIDKey: CLIENT_LOG_KEY,
    globals: window,
});

export const setupAnalytics = () => {
    const config = {
        [statlerProviderName]: {
            resendLimit: 5,
        },
    };

    Analytics.addPluggable(statlerUIAnalyticsProvider);
    Analytics.autoTrack("session", {
        enable: true,
        attributes: {
            url: window.location.href,
            userAgent: window.navigator && window.navigator.userAgent,
        },
        provider: statlerProviderName,
    });
    Analytics.configure(config);
    trackPerformanceMetrics({ Analytics, providerName: statlerProviderName });
};
