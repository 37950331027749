import { Component } from "react";
import Analytics from "@aws-amplify/analytics";

import {
    statlerProviderName,
    statlerUIAnalyticsProvider,
} from "../../utils/Analytics";

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error) {
        const errorEvent = {
            name: error.name,
            message: error.message,
        };
        this.recordError(errorEvent);
    }

    componentDidMount() {
        const win = this.props.depWindow || window;
        win.addEventListener("error", this.onError);
    }

    componentWillUnmount() {
        const win = this.props.depWindow || window;
        win.removeEventListener("error", this.onError);
    }

    onError = (err) => {
        const { message, type } = err;
        const errorEvent = {
            name: type || "Error",
            message,
        };

        this.recordError(errorEvent);
        this.setState({ hasError: true });
    };

    recordError({ name, message }) {
        Analytics.record(
            {
                name,
                message,
                type: "Error",
                attributes: {
                    labTitle: statlerUIAnalyticsProvider.labTitle,
                },
            },
            statlerProviderName
        );
    }

    render() {
        const { children, fallback } = this.props;
        if (this.state.hasError) {
            return fallback ? fallback() : null;
        }
        return children;
    }
}
