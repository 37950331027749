import React, { useEffect } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import _get from "lodash/get";

import {
    Loader,
    GetLabQuery,
    GetBlueprintQuery,
    GetMarkdownQuery,
    LabWrapper,
    ErrorMessage,
} from "../../components";
import { isEmpty } from "../../utils/helpers";
import {
    statlerUIAnalyticsProvider,
    setupAnalytics,
} from "../../utils/Analytics";
import { performanceTracker } from "../../utils/performance";

const isSessionLoading = ({ data, error }) =>
    (!error || error.status === 429) && data.sessionLoading;

const SessionPage = ({ labId }) => {
    const tapLabResponse = ({ data, error }) => {
        if (!data) return;
        const { start, end } = performanceTracker.markProvisioningTime;

        if (data.sessionLoading) {
            start();
        } else if (data.sessionReady) {
            end();
            performanceTracker.recordProvisioningTime();
        }
    };

    useEffect(() => {
        setupAnalytics();
        performanceTracker.markFirstPaint.end();
        performanceTracker.markLoadingScreen.start();
    }, []);

    return (
        <GetLabQuery
            labId={labId}
            retryPredicate={isSessionLoading}
            tapResponse={tapLabResponse}
        >
            <GetBlueprintQuery labId={labId}>
                <GetMarkdownQuery>
                    {({ GetLab, GetBlueprint, GetMarkdown }) => {
                        const labErrorStatus = _get(GetLab.error, "status");

                        if (GetBlueprint.data.title) {
                            statlerUIAnalyticsProvider.addTitle(
                                GetBlueprint.data.title
                            );
                        }

                        const fallback = () => {
                            switch (labErrorStatus) {
                                case 401:
                                case 404:
                                    return <ErrorMessage type="notFound" />;
                                case 403:
                                    return <ErrorMessage type="unauthorized" />;
                                default:
                                    return <ErrorMessage type="fatal" />;
                            }
                        };

                        return (
                            <Loader
                                visible={isEmpty(GetLab.data)}
                                hasError={
                                    labErrorStatus >= 0 &&
                                    labErrorStatus !== 429
                                }
                                fallback={fallback}
                            >
                                <LabWrapper
                                    {...{ GetLab, GetBlueprint, GetMarkdown }}
                                />
                            </Loader>
                        );
                    }}
                </GetMarkdownQuery>
            </GetBlueprintQuery>
        </GetLabQuery>
    );
};

export default SessionPage;
