import React from "react";
import { useIntl } from "react-intl";

import messages from "./TableOfContents.messages";
import "./TableOfContents.scss";

const TableOfContents = ({ tocElements }) => {
    const { formatMessage } = useIntl();
    return (
        <div className="sidebar-toc">
            <h3 className="sidebar-toc__title">
                {formatMessage(messages.tableOfContentsTitle)}
            </h3>
            <ol className="sidebar-toc__list">
                {tocElements.map(({ anchor, content }, i) => (
                    <li key={anchor} className="sidebar-toc__list__item">
                        <a
                            href={`#${encodeURIComponent(anchor)}`}
                            data-testid={i === 0 ? "sidebar-link" : undefined}
                        >
                            {content}
                        </a>
                    </li>
                ))}
            </ol>
        </div>
    );
};

export default TableOfContents;
