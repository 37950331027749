import logger from "./logger";

const randomNumberBetween = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);

export const calculateDelay = (options, attempt) => {
    // Avoid delays when running test suite.
    if (process.env.NODE_ENV === "test") return 0;

    const expBackoff = options.expBackoff ? Math.pow(2, attempt) : 1;
    // to jitter, we get a value that is somewhere between 20% below and above
    // the interval value
    const jitterAdjust = options.interval * 0.2;
    const jitter = options.jitter
        ? randomNumberBetween(-jitterAdjust, jitterAdjust)
        : 0;
    return Math.floor(options.interval * expBackoff + jitter);
};

const shouldRetry = (options, error) => {
    return options.retryPredicate ? options.retryPredicate(error) : true;
};

/**
 * Wraps a function that returns a promise with retry mechanics.
 *
 * @param {Function} asyncFn Function that returns a promise
 * @param {Object} [options={}]
 * @param {Number} [options.retries=3]
 * @param {Number} [options.interval=100]
 * @param {Boolean} [options.expBackoff=true] Adds exponential backoff between retries
 * @param {Boolean} [options.jitter=true] Adds jitter to retries
 * @param {Function<Boolean>} [options.retryPredicate] Fine tune when to retry, gets passed the caught error.
 * @returns {Promise}
 */
export const retry = (asyncFn, options, attempt = 1) => {
    const defaultOptions = {
        retries: 3,
        interval: 500,
        expBackoff: true,
        jitter: true,
    };
    const opts = Object.assign({}, defaultOptions, options);
    const promise = asyncFn();

    if (!isPromise(promise)) {
        logger.error("[retry] The function needs to return a Promise.");
        throw new Error("asyncFn did not resolve to a Promise");
    }

    return promise.catch(err => {
        logger.log("[retry] Caught error.", err);

        if (!shouldRetry(opts, err)) {
            logger.log("[retry] retryPredicate opted not to retry:", err);
            return Promise.reject(err);
        }

        if (opts.retries === 0) {
            logger.log("[retry] No more retries, failing.");
            return Promise.reject(err);
        }

        return waitFor(calculateDelay(opts, attempt)).then(() => {
            logger.log(
                `[retry] Retrying async call, ${opts.retries} attempts left.`
            );
            return retry(
                asyncFn,
                {
                    ...opts,
                    retries: opts.retries - 1,
                },
                attempt + 1
            );
        });
    });
};

/**
 * Checks if the passed object is a Promise.
 *
 * @param {Object} object
 * @returns {Boolean}
 */
export const isPromise = object =>
    // There is currently no great way to assert this absolutely. Right now the
    // existance of a then-able function is the only consistent pattern to check.
    Boolean(object && object.then && typeof object.then === "function");

/**
 * Returns a promise that resolves after `ms`.
 *
 * @param {Number} ms Timeout in ms
 * @returns {Promise}
 */
export const waitFor = ms => new Promise(resolve => setTimeout(resolve, ms));
