// the [^] captures any character, including newlines
const COMMENT_RE = new RegExp("(<|&lt;)!--[^]*--(>|&gt;)");
const OPEN_COMMENT_RE = new RegExp("(<|&lt;)!--");
const CLOSE_COMMENT_RE = new RegExp("--(>|&gt;)");

const isInRange = n => ([min, max]) => n >= min && n <= max;

const emptyToken = token => {
    token.hidden = true;
    token.content = "";
    if (Array.isArray(token.children)) {
        token.children = token.children.map(emptyToken);
    }
    return token;
};

const stripMultilineComments = tokens => {
    // need to track where a multiline comment starts and ends
    // to effectively empty it out
    const ranges = tokens.reduce((acc, { content, type }, i) => {
        if (type === 'fence') { return acc; }
        if (OPEN_COMMENT_RE.test(content) && !CLOSE_COMMENT_RE.test(content)) {
            return [...acc, [i]];
        } else if (
            !OPEN_COMMENT_RE.test(content) &&
            CLOSE_COMMENT_RE.test(content) &&
            acc[acc.length - 1]
        ) {
            return [
                ...acc.slice(0, acc.length - 1),
                [acc[acc.length - 1][0], i],
            ];
        }
        return acc;
    }, []);

    return tokens.map((token, i) => {
        if (ranges.some(isInRange(i))) {
            return emptyToken(token);
        }
        return token;
    });
};

const stripComments = tokens => {
    return tokens.map(token => {
        token.content = token.content.replace(COMMENT_RE, "");
        if (Array.isArray(token.children)) {
            token.children = stripComments(
                stripMultilineComments(token.children)
            );
        }
        return token;
    });
};

const htmlComment = ({ tokens }) =>
    stripComments(stripMultilineComments(tokens));

/**
 * Returns Markdown-it plugin for use with instance::use(plugin, options) that
 * supports detects and hides html comment blocks.
 */
export default () => ({
    plugin: md => {
        md.core.ruler.push("html_comment", htmlComment);
    },
});
