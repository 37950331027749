const environment = window => {
    if (window.__CONFIGURATION__) {
        return window.__CONFIGURATION__;
    }

    if (
        window.sessionStorage.getItem('__E2E_TEST__') &&
        process.env.NODE_ENV !== 'production'
    ) {
        try {
            return JSON.parse(window.sessionStorage.getItem('__E2E_TEST__'));
        } catch (error) {
            console.log(error);
        }
    }

    return {};
};

export default environment;
