import { UserManager } from "oidc-client";
import environment from "../.config/environment";

export const createVibeOidcUserManager = globals => {
    const {
        authorizer: {
            client_id,
            issuer,
            authorization_endpoint,
            token_endpoint,
            end_session_endpoint,
        },
    } = environment(globals);
    const { origin } = globals.location;
    const redirect_uri = `${origin}/callback`;
    const callbackUrl = encodeURIComponent(redirect_uri);
    const logoutUrl = `${end_session_endpoint}?client_id=${client_id}&logout_uri=${callbackUrl}`;
    const beakerDevUserManagerParams = {
        client_id,
        authority: issuer,
        redirect_uri,
        response_type: 'code',
        automaticSilentRenew: true,
        ...(authorization_endpoint
            ? {
                  loadUserInfo: false,
                  metadata: {
                      authorization_endpoint,
                      token_endpoint,
                      issuer,
                      end_session_endpoint: logoutUrl,
                  },
              }
            : {}),
    };

    const manager = new UserManager(beakerDevUserManagerParams);
    manager.startSilentRenew();
    return manager;
};
