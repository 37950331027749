import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Flashbar } from "@amzn/awsui-components-react";

import { ErrorMessage } from "..";
import messages from "./ContentMessage.messages";
import { authorizationError } from "../../utils/helpers";

const ContentMessage = ({
    xhrErrorStatuses,
    sessionLoading,
    sessionReady,
    isInvoking,
    sessionError,
}) => {
    const { formatMessage } = useIntl();
    const [isVisible, isVisibleSet] = useState(true);
    const onDismiss = () => isVisibleSet(false);

    if (
        sessionError ||
        (xhrErrorStatuses.length && !xhrErrorStatuses.includes(429))
    ) {
        let type = "fatal";

        if (xhrErrorStatuses.some(authorizationError)) {
            type = "unauthorized";
        } else if (sessionError) {
            type = "sessionError";
        }

        return <ErrorMessage type={type} />;
    } else if (sessionLoading && !isInvoking) {
        return (
            <Flashbar
                items={[
                    {
                        loading: true,
                        content: formatMessage(
                            messages.sessionProvisioningMessage
                        ),
                    },
                ]}
            />
        );
    } else if (sessionReady && !isInvoking && isVisible) {
        return (
            <Flashbar
                items={[
                    {
                        type: "success",
                        dismissible: true,
                        dismiss: onDismiss,
                        dismissLabel: formatMessage(messages.closeReadyMessage),
                        content: (
                            <span data-testid="services-ready">
                                {formatMessage(messages.sessionReadyMessage)}
                            </span>
                        ),
                    },
                ]}
            />
        );
    }
    return null;
};

export default ContentMessage;
