import "@amzn/awsui-components-react/index.css";
import React from "react";
import { render } from "react-dom";
import { IntlProvider, useIntl } from "react-intl";
import { BrowserRouter, useLocation } from "react-router-dom";
import { ErrorBoundary } from "./components";
import { UserManagerContext } from "./components/Auth";
import App from "./containers/App";
import translations from "./i18n";
import { FetchProvider } from "./reducers";
import "./scss/global.scss";
import { FetchClientBuilder } from "./utils/FetchClientBuilder";
import { parseURIParams } from "./utils/helpers";
import { performanceTracker } from "./utils/performance";
import { createVibeOidcUserManager } from "./utils/vibeOidcUserManager";

const Provides = ({ contexts = [], children = null }) =>
    contexts
        .map(contextArg => {
            if (Array.isArray(contextArg)) {
                const [Provider, value] = contextArg;
                return ({ children }) => (
                    <Provider value={value}>{children}</Provider>
                );
            }
            return contextArg;
        })
        .reduceRight(
            (reactNode, Wrapper) => <Wrapper>{reactNode}</Wrapper>,
            children
        );

const parseLocaleFromQueryString = (search, fallback = "en-US") => {
    const { locale = "" } = parseURIParams(search.slice(1)) || {};
    return locale in translations ? locale : fallback;
};

const ProvidesLocalization = ({ children = null }) => {
    const location = useLocation();
    const locale = parseLocaleFromQueryString(location.search);
    return (
        <IntlProvider locale={locale} messages={translations[locale]}>
            {children}
        </IntlProvider>
    );
};

const ProvidesAPIClient = ({ children = null }) => {
    const { locale } = useIntl();
    return (
        <FetchProvider client={new FetchClientBuilder(window, locale)}>
            {children}
        </FetchProvider>
    );
};

const ProvidesUserManager = ({ children = null }) => (
    <UserManagerContext.Provider value={createVibeOidcUserManager(window)}>
        {children}
    </UserManagerContext.Provider>
);

const Root = () => {
    return (
        <ErrorBoundary>
            <BrowserRouter>
                <Provides
                    contexts={[
                        ProvidesUserManager,
                        ProvidesLocalization,
                        ProvidesAPIClient,
                    ]}
                >
                    <App />
                </Provides>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

performanceTracker.markAppReady.start();

render(<Root />, document.getElementById("root"));

performanceTracker.markConfigTime.end();
