import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import routes from "../../utils/routes";

import "./DevResources.scss";

// Update this list to quickly link to any other mocked labs
const mockedLabs = [
    {
        name: "lab-1-ssm",
        description: "A lab in current locale returned without issues.",
    },
    {
        name: "lab-1-ssm?locale=en-US",
        description: "A lab in english returned without issues.",
    },
    {
        name: "lab-1-ssm?locale=ja-JP",
        description: "A lab japanese returned without issues.",
    },
    {
        name: "lab-1-ssm?locale=ko-KR",
        description: "A lab korean returned without issues.",
    },
    {
        name: "lab-1-ssm?locale=zh-CN",
        description: "A lab in chinese returned without issues.",
    },
    {
        name: "lab-1-ssm?locale=zh-TW",
        description: "A lab in tawainese returned without issues.",
    },
];

const staticRoutes = [
    {
        path: routes.paths.notFound,
        description: "The page when a lab or any path is not found.",
    },
];

const DevResources = ({ match: { isExact } }) => {
    return (
        <main className="dev-resources">
            {isExact ? <Root /> : null}
            <Route
                path={routes.paths.devResources("/end-session-redirect-page")}
                component={SessionEnded}
            />
        </main>
    );
};

const Root = () => (
    <Fragment>
        <h1>Welcome Developer!</h1>
        <p>
            You were redirected here because you did not specify a lab to access
            in mock data mode. You probably want to navigate to one of the
            following:
        </p>
        <ul>
            {mockedLabs.map(lab => (
                <li key={lab.name}>
                    <a
                        className="dev-resources__link"
                        href={routes.paths.session(lab.name)}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {lab.name}
                    </a>
                    : {lab.description}
                </li>
            ))}
            {staticRoutes.map(page => (
                <li key={page.path}>
                    <a
                        href={page.path}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="dev-resources__link"
                    >
                        {page.path}
                    </a>
                    : {page.description}
                </li>
            ))}
        </ul>
        <br />
        <p>
            The data configured under the directory <code>/mockData</code> will
            be returned from XHR calls via the express server running on
            localhost:5000
        </p>
    </Fragment>
);

const SessionEnded = () => {
    return (
        <Fragment>
            <h1>Welcome Developer who ended a session!</h1>
            <p>
                Some mock sessions are configured to redirect to this page upon
                completion.
            </p>
            <p>You may close this window.</p>
        </Fragment>
    );
};

export default DevResources;
