import environment from "../.config/environment";
import { mockDataMode } from "./helpers";

export const apis = globals => {
    const { host_name } = environment(globals);
    const statler_bff = mockDataMode() ? "/v1" : host_name.statler_bff;
    return {
        CancelLab: {
            baseUrl: `${statler_bff}/CancelLab`,
        },
        GetBlueprint: {
            baseUrl: `${statler_bff}/GetBlueprint`,
        },
        GetLab: {
            baseUrl: `${statler_bff}/GetLab`,
        },
        GetSession: {
            baseUrl: `${statler_bff}/GetSession`,
        },
        StartSession: {
            baseUrl: `${statler_bff}/StartSession`,
        },
        GetMarkdown: {
            baseUrl: mockDataMode()
                ? `${statler_bff}/GetMarkdown/`
                : "", // returned markdown urls are absolute signed urls
            onSuccess: res => res.text(),
        },
        SendClientLogs: {
            baseUrl: `${statler_bff}/SendClientLogs`,
        },
    };
};
