import React from "react";
import { useIntl } from "react-intl";

import messages from "./Sidebar.messages";

const Sidebar = ({ children }) => {
    const { formatMessage } = useIntl();

    return (
        <div className="awsui-util-help-panel">
            <div className="awsui-util-help-panel-header">
                <h2>{formatMessage(messages.sidebarTitle)}</h2>
            </div>
            {children}
        </div>
    );
};

export default Sidebar;
