import React, { createContext } from "react";
import { useLocation } from "react-router-dom";

import userReducer from "./fetcher.reducer";
import { createStore } from "..";
import { apis } from "../../utils/apis";
import { statlerUIAnalyticsProvider } from "../../utils/Analytics";

export const createApiStates = apiMap => {
    const mappedApis = {};
    for (const key in apiMap) {
        mappedApis[key] = {
            loading: false,
            error: null,
            data: {},
            ...apiMap[key],
        };
    }
    return mappedApis;
};

export const FetchCtx = createContext({});

export const FetchProvider = ({ client, children }) => {
    const apiDefinitions = apis(client.globals);
    const INITIAL_STATE = createApiStates(apiDefinitions);
    const store = createStore(userReducer, INITIAL_STATE);
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    statlerUIAnalyticsProvider.addEndpoint(apiDefinitions.SendClientLogs.baseUrl);
    if (queryParams.get("reference")) {
        const [classroomId, sessionId] = queryParams.get("reference").split("::");
        statlerUIAnalyticsProvider.addMetadata({ classroomId, sessionId });
    }

    client.addDispatch(store.dispatch).addApiDefinitions(apiDefinitions);
    return (
        <FetchCtx.Provider value={{ fetch: client.fetch, state: store.state }}>
            {children}
        </FetchCtx.Provider>
    );
};
