import { useContext } from "react";
import { FetchCtx } from "../../reducers";

const EMPTY_STATE = (name) => ({
    data: {},
    loading: false,
    error: null,
});

export const useQuery = (name) => {
    const { fetch, state } = useContext(FetchCtx);
    const { loading, error, data } = state[name] || EMPTY_STATE(name);
    const returnedState = {
        loading,
        error,
        data,
    };

    return {
        fetch: fetch(name),
        ...returnedState,
    };
};
